import { Navigate, Route, Routes } from "react-router-dom";

import Jobs from "./core/scenes/jobs";
import IpSet from "./core/scenes/ipSet";
import Events from "./core/scenes/events";
import Dashboard from "./core/scenes/dashboard";
import DocumentJobs from "./core/scenes/documentJobs";
import LoginWrapper from "./core/scenes/auth/LoginWrapper";
import VerifyWrapper from "./core/scenes/auth/VerifyWrapper";
import StateMachinesList from "./core/scenes/stateMachines/list";
import StateMachineSummary from "./core/scenes/stateMachines/summary";
import StateMachineExecutionSummary from "./core/scenes/stateMachines/executionSummary";
import Changelog from "core/scenes/changelog";
import auth from "./app/authHooks";
import Integrations from "core/scenes/intelliflo-integrations";
import AddIntegration from "core/scenes/intelliflo-integrations/add-intelliflo-tenant/AddIntellifloTenant";
import IOConfig from "core/scenes/io-config";
import AddEditIOConfig from "core/scenes/io-config/add-edit-config";
import OnboardingUpload from "core/scenes/onboarding";
import InvestorUsers from "core/scenes/investor-users";
import AddEditInvestorUsers from "core/scenes/investor-users/add-edit-investor-users";
import AdviserUsers from "core/scenes/advisor-users";
import AddEditAdviserUsers from "core/scenes/advisor-users/add-edit-adviser-users";
import JobDetail from "core/scenes/jobdetail";
import JobItemDetail from "core/scenes/jobdetail/job-item-detail";
import EntityMappingScreen from "core/scenes/entity-mapping";
import UsersActivity from "core/scenes/user-activity";
import UsersActivityDetail from "core/scenes/user-activity/user-activity-detail";

const ProtectedWrapper = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = auth.useAuth();
  return isAuthenticated ? children : <Navigate to="/auth/login" replace />;
};

const Router = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedWrapper>
            <Dashboard />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/changelog"
        element={
          <ProtectedWrapper>
            <Changelog />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/state-machines"
        element={
          <ProtectedWrapper>
            <StateMachinesList />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/state-machines/:id"
        element={
          <ProtectedWrapper>
            <StateMachineSummary />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/state-machines/:machineId/execution/:id"
        element={
          <ProtectedWrapper>
            <StateMachineExecutionSummary />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedWrapper>
            <Dashboard />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/jobs"
        element={
          <ProtectedWrapper>
            <Jobs />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/jobs/:name"
        element={
          <ProtectedWrapper>
            <JobDetail/>
          </ProtectedWrapper>
        }
      />

      <Route
        path="/jobs/:name/:id"
        element={
          <ProtectedWrapper>
            <JobItemDetail/>
          </ProtectedWrapper>
        }
      />

      <Route
        path="/events"
        element={
          <ProtectedWrapper>
            <Events />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/ip-set"
        element={
          <ProtectedWrapper>
            <IpSet />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/integrations"
        element={
          <ProtectedWrapper>
            <Integrations />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/integrations/new"
        element={
          <ProtectedWrapper>
            <AddIntegration />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/integrations/:id"
        element={
          <ProtectedWrapper>
            <AddIntegration />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/io-config"
        element={
          <ProtectedWrapper>
            <IOConfig />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/io-config/add-edit"
        element={
          <ProtectedWrapper>
            <AddEditIOConfig />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/io-config/add-edit/:id"
        element={
          <ProtectedWrapper>
            <AddEditIOConfig />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/onboarding"
        element={
          <ProtectedWrapper>
            <OnboardingUpload />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/onboarding/csv-to-json"
        element={
          <ProtectedWrapper>
            <OnboardingUpload />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/investor-users"
        element={
          <ProtectedWrapper>
            <InvestorUsers />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/investor-users/add-edit"
        element={
          <ProtectedWrapper>
            <AddEditInvestorUsers />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/investor-users/add-edit/:id"
        element={
          <ProtectedWrapper>
            <AddEditInvestorUsers />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/investor-users/user-activity/:userId"
        element={
          <ProtectedWrapper>
            <UsersActivity />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/investor-users/user-activity/:userId/:eventId"
        element={
          <ProtectedWrapper>
            <UsersActivityDetail />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/adviser-users"
        element={
          <ProtectedWrapper>
            <AdviserUsers />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/adviser-users/add-edit"
        element={
          <ProtectedWrapper>
            <AddEditAdviserUsers />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/adviser-users/add-edit/:id"
        element={
          <ProtectedWrapper>
            <AddEditAdviserUsers />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/adviser-users/user-activity/:userId"
        element={
          <ProtectedWrapper>
            <UsersActivity />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/adviser-users/user-activity/:userId/:eventId"
        element={
          <ProtectedWrapper>
            <UsersActivity />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/entity-mapping"
        element={
          <ProtectedWrapper>
            <EntityMappingScreen/>
          </ProtectedWrapper>
        }
      />

      <Route path="/auth/login" element={<LoginWrapper />} />
      <Route path="/auth/verify" element={<VerifyWrapper />} />
    </Routes>
  );
};

export default Router;
