import { signIn, signOut, confirmSignIn, getCurrentUser, fetchUserAttributes } from "aws-amplify/auth";
import React, { createContext, useContext, useEffect, useState } from "react";

export type MfaType = "SMS_MFA" | "SOFTWARE_TOKEN_MFA" | null;

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  username: string;
  attributes: {
    [key: string]: any;
  };
  signIn: (username: string, password: string) => Promise<Result>;
  signOut: () => void;
  verifyTotp: (code: string) => Promise<Result>;
  currentChallenge: string | null;
}

interface Result {
  success: boolean;
  message: string;
  path?: string;
}

type Props = {
  children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

export const ProvideAuth: React.FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [currentChallenge, setCurrentChallenge] = useState<null|string>(null);
  const [attributes, setAttributes] = useState({});

  const setCurrentUser = () => {
    Promise.all([getCurrentUser(), fetchUserAttributes()])
      .then(([user, attributes]) => {
        setUsername(user.username);
        setIsAuthenticated(true);
        setIsLoading(false);
        setAttributes(attributes);
      })
      .catch(() => {
        setUsername("");
        setIsAuthenticated(false);
        setIsLoading(false);
        setAttributes({});
      });
  };

  useEffect(() => {
    setCurrentUser();
  }, []);

  const handleSignIn = async (username: string, password: string) => {
    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });
      if (!isSignedIn) {
        setCurrentChallenge(nextStep.signInStep);
        return { success: true, message: "TOTP", path: "/auth/verify" };
      } else {
        setIsAuthenticated(true);
        fetchUserAttributes().then(setAttributes);
        setCurrentChallenge(null);
        return { success: true, message: " " , path:"/" };
      }
    } catch (error) {
      return {
        success: false,
        message: "LOGIN FAILED",
      };
    }
  };

  const verifyTotp = async (code: string) => {
    try {
      const { isSignedIn, nextStep } = await confirmSignIn({ challengeResponse: code });
      setIsAuthenticated(isSignedIn);
      if (isSignedIn) setCurrentUser();
      console.log("verifyTotp response: ", isSignedIn, nextStep);
      return { success: true, message: "" };
    } catch (error) {
      setUsername("");
      setIsAuthenticated(false);
      setAttributes({});
      return {
        success: false,
        message: "VERIFY TOTP FAIL",
      };
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setUsername("");
      setIsAuthenticated(false);
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "LOGOUT FAIL",
      };
    }
  };

  return {
    isLoading,
    isAuthenticated,
    attributes,
    username,
    verifyTotp,
    signOut: handleSignOut,
    signIn: handleSignIn,
    currentChallenge,
  };
};

export default {
  useAuth,
  ProvideAuth,
};
