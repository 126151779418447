import React, { forwardRef } from "react";

import {
  TableCell,
  TableRow
} from "@mui/material";

import { useNavigate } from "react-router";
import MenuComponent, { MenuItem } from "components/MenuComponent";
import { ExternalEntityMap } from "app/services/types.generated";

interface TableRowProps {
  // Define any other props you need for the child component
  row: ExternalEntityMap;
}

const TenantRow = forwardRef<HTMLTableRowElement, TableRowProps>((props, ref) => {
  const navigate = useNavigate();
  const row = props.row;

  const options: MenuItem[] = [
    {
      label: "Edit",
      onClick: () => navigate("/integrations/" + row.id)
    }
  ];

  return (
    <React.Fragment>
      <TableRow
        ref={ref}
        sx={{ borderBottom: "none", "& > *": { borderBottom: "unset" } }}
      >
        <TableCell>{row.externalId}</TableCell>
        <TableCell>{row.internalId}</TableCell>
        <TableCell>{row?.metadata?.displayName}</TableCell>
        <TableCell>
          <MenuComponent options={options} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
});

export default TenantRow;
