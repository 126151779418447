import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, CircularProgress, FormControlLabel, Pagination, Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import Header from "components/Header";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router";
import loadingStore from "core/utils/loading";
import { observer } from "mobx-react";
import investorStore from "../api";
import InvestorUsersRow from "./investor-users-row";
import { Investor } from "../model";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const TablePlaceholder: React.FC<{ children: ReactNode }> = ({ children }) => (
  <TableRow>
    <TableCell colSpan={9} align="center">
      {children}
    </TableCell>
  </TableRow>
);

function InvestorUsersTable() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { getInvestors } = investorStore;
  const { loading } = loadingStore;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize] = useState(10);
  const [sortField, setSortField] = useState<keyof Investor>("created_at");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState<Date | any>(null);
  const [isAfter, setIsAfter] = useState(false);
  const [users, setUsers] = useState<Investor[]>([]);

  useEffect(() => {
    onInit((searchText),
      currentPage,
      sortField,
      sortOrder,
      (isAfter ? "after" : "before"),
      (selectedDate ? selectedDate.toISOString() : ""));
  }, [searchText, currentPage, sortField, sortOrder, isAfter, selectedDate]);

  const onInit = async (searchText: string,
    page: number,
    field: keyof Investor | null,
    order: "asc" | "desc",
    dateDirection: string,
    date: any) => {
    try {
      const users = await getInvestors(searchText, page - 1, pageSize, field, order, dateDirection, date);
      setUsers(users?.data || []);
      setTotalPages(Math.ceil((users?.total || 0) / pageSize));
    } catch (error) {
      console.error("Failed to fetch investors:", error);
    }
  };

  const handleSort = (field: keyof Investor) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortField(field);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  return (
    <Box  sx={{ paddingBottom: "64px" }}>
      <Box display="flex" justifyContent="space-between">
        <Header title="Investor Users" subtitle="Manage Investor Users" />
        <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => navigate("/investor-users/add-edit")}
          >
              Add Investor Users
          </Button>
        </Box>
      </Box>

      <Box py="20px">
        <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
          <AccordionSummary sx={{ "&.Mui-expanded": { minHeight: "32px" } }} expandIcon={<ExpandMoreIcon />}>
            <Typography>Filters</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "16px" }}>
            <Box display="flex" gap="16px" flexDirection="column" alignItems="flex-start">
              <TextField
                fullWidth
                label="Search"
                variant="outlined"
                sx={{ flex: 1 }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />

              <Box display="flex" alignItems="center" gap="8px" width="100%">
                <Box display="flex" alignItems="center" gap="8px" flex={1}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Select Date"
                      value={selectedDate ? selectedDate : null}
                      onChange={(newDate: Date | null) => setSelectedDate(newDate)}
                      slotProps={{
                        textField: {
                          InputProps: { readOnly: true, disabled: true },
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAfter}
                        onChange={(e) => setIsAfter(e.target.checked)}
                      />
                    }
                    label={isAfter ? "After" : "Before"}
                  />
                </Box>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setSearchText("");
                    setSelectedDate(null);
                    setIsAfter(false);
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {[
                { field: "first_name", label: "Name" },
                { field: "username", label: "Username" },
                { field: "email", label: "Email" },
                { field: "phone_number", label: "Phone" },
                { field: "created_at", label: "Created At" },
              ].map(({ field, label }) => (
                <TableCell key={field} onClick={() => handleSort(field as keyof Investor)}>
                  <Typography noWrap variant="h5">
                    {label}{" "}
                    <span style={{ opacity: 0.75 }}>
                      {sortField === field ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                    </span>
                  </Typography>
                </TableCell>
              ))}
              <TableCell>
                <Typography noWrap variant="h5">
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TablePlaceholder>
                <CircularProgress />
              </TablePlaceholder>
            )}
            {!loading && !users?.length && (
              <TablePlaceholder>
                <Typography variant="h5">No Data</Typography>
              </TablePlaceholder>
            )}
            {!loading && users?.map((row, index) => (
              <InvestorUsersRow row={row} key={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px",
          boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Pagination
          className="my-3"
          count={totalPages}
          page={currentPage}
          variant="outlined"
          onChange={(event, value) => setCurrentPage(value)}
        />
      </Box>
    </Box>
  );
}

export default observer(InvestorUsersTable);
