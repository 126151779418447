import React from "react";
import { enqueueSnackbar } from "notistack";
import { FastField, FormikErrors } from "formik";
import _deepEqual from "lodash/isEqualWith";
import { Button, Checkbox, TableCell, TableRow } from "@mui/material";

import { IpListElement } from "./types";
import { TextInput, CheckboxInput } from "../../../components/inputs";
import { transformedGetIpSet } from "./api";

export const isEqual = (value: IpListElement, initialValue: IpListElement): boolean | null => {
  if (!initialValue) return null;
  if (value.id !== initialValue.id) {
    console.log("ID NOT EQUAL");
  }
  return _deepEqual(value, initialValue, (_a, _b, indexOrKey) => {
    if (indexOrKey === "id") return true;
  });
};

type Props = {
  index: number
  value: IpListElement
  initialValue: IpListElement
  validateForm: (values?: any) => Promise<FormikErrors<{tableData: transformedGetIpSet}>>
  currentlyEditing: number
  setCurrentlyEditing: React.Dispatch<React.SetStateAction<number>>
}
const IpSetRow: React.FC<Props> = ({
  index,
  value,
  validateForm,
  currentlyEditing,
  setCurrentlyEditing
}) => {
  if (currentlyEditing !== index) {
    return (
      <TableRow>
        <TableCell>{value.ip}</TableCell>
        <TableCell>{value.owner}</TableCell>
        <TableCell>{value.comment}</TableCell>
        <TableCell>
          <Checkbox sx={{ padding: 0 }} checked={value.isActive}/>
        </TableCell>
        <TableCell>{value.activeMessage}</TableCell>
        <TableCell align="center">
          <Button
            size="small"
            variant="contained"
            onClick={() => setCurrentlyEditing(index)}
            disabled={currentlyEditing !== -1}
          >
            Edit
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell sx={{ verticalAlign: "baseline" }}>
        <FastField size="small" component={TextInput} name={`tableData[${index}].ip`}  />
      </TableCell>
      <TableCell sx={{ verticalAlign: "baseline" }}>
        <FastField size="small" component={TextInput} name={`tableData[${index}].owner`} />
      </TableCell>
      <TableCell sx={{ verticalAlign: "baseline" }}>
        <FastField size="small" component={TextInput} name={`tableData[${index}].comment`} />
      </TableCell>
      <TableCell>
        <FastField sx={{ padding: 0 }} component={CheckboxInput} name={`tableData[${index}].isActive`} />
      </TableCell>
      <TableCell sx={{ verticalAlign: "baseline" }}>
        <FastField size="small" component={TextInput} name={`tableData[${index}].activeMessage`} />
      </TableCell>
      <TableCell align="center">
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            validateForm().then((res) => {
              if (!res.tableData) {
                setCurrentlyEditing(-1);
              } else if (typeof res.tableData === "string") {
                enqueueSnackbar(res.tableData, { variant: "error" });
              }
            });
          }}
        >
          Confirm
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(
  IpSetRow,
  (prevProps, nextProps) => _deepEqual(prevProps, nextProps)
);
