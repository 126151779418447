import { useNavigate, useParams } from "react-router";
import { CreateIoConfigMutation, CreateIoConfigMutationVariables, UpdateIoConfigMutation, UpdateIoConfigMutationVariables, useCreateIoConfigMutation, useGetIoConfigByIdQuery, useUpdateIoConfigMutation } from "../api/io-config.generated";
import { Box, FormControl, Grid, TextField, Link, Button, Typography, InputLabel, Select, MenuItem, InputAdornment, IconButton } from "@mui/material";
import { CardComponent } from "components/card/Card";
import { Formik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { enqueueSnackbar } from "notistack";
import { IoConfigService } from "../api";
import PageWrapper from "components/PageWrapper";

function AddEditIOConfig() {
  const navigate = useNavigate();
  const [showKey, setShowKey] = useState(false);
  const handleClickShowKey = () => setShowKey(!showKey);
  const handleMouseDownKey = () => setShowKey(!showKey);
  const [showSecret, setShowSecret] = useState(false);
  const handleClickShowSecret = () => setShowSecret(!showSecret);
  const handleMouseDownSecret = () => setShowSecret(!showSecret);
  const [createIoConfig] = IoConfigService.useCreateIoConfigMutation();
  const [updateIoConfig] = IoConfigService.useUpdateIoConfigMutation();
  let ioConfigListData: any;

  const initialValues = {
    id: "",
    type: "",
    provider: "",
    providerId: "",
    appId: "",
    apiKey: "",
    authorisationToken: "",
    apiEndPoint: "",
    hostEndPoint: "",
    scope: ""
  } as any;

  const params = useParams();
  const isEdit = !!params?.id;
  const [dataValue, setDataValue] = useState<any>(initialValues);

  if (isEdit && params?.id) {
    ioConfigListData = useGetIoConfigByIdQuery({ id: params?.id });
  }

  useEffect(() => {
    if (isEdit && ioConfigListData) {
      setDataValue(ioConfigListData.data?.getIoConfigById?.items?.[0] || initialValues);
    }
  }, [ioConfigListData]);

  const ioConfigScheme = yup.object({
    id: yup.string().optional(),
    type: yup.string().required("Type is required."),
    provider: yup.string().required("Provider is required."),
    providerId: yup.string().required("Provider Id is required."),
    appId: yup.string().required("App Id is required."),
    apiKey: yup.string().required("Api Key is required."),
    authorisationToken: yup.string().required("Authorisation Token is required."),
    apiEndPoint: yup.string().required("Api End Point is required."),
    hostEndPoint: yup.string().required("Host End Point is required."),
    scope: yup.string().required("Scope is required.")
  });

  const handleSubmitForm = async (value: any) => {
    if (isEdit && params?.id) {
      const data: UpdateIoConfigMutationVariables = { input: value };
      const response = await updateIoConfig(data) as { data: UpdateIoConfigMutation | undefined };
      if (response?.data?.updateIoConfig?.success) {
        enqueueSnackbar("IO App Config has been successfully updated!!!", { variant: "success" });
        navigate("/io-config");
      } else {
        enqueueSnackbar(response?.data?.updateIoConfig?.errorMessage || "Failed to update IO App Config!!!", { variant: "error" });
      }
    } else {
      const data: CreateIoConfigMutationVariables = { input: value };
      const response = await createIoConfig(data) as { data: CreateIoConfigMutation | undefined };
      if (response?.data?.createIoConfig?.success) {
        enqueueSnackbar("IO App Config has been successfully created!!!", { variant: "success" });
        navigate("/io-config");
      } else {
        enqueueSnackbar(response?.data?.createIoConfig?.errorMessage || "Failed to create IO App Config!!!", { variant: "error" });
      }
    }
  };

  const formatError = (error: any): string | undefined => {
    if (typeof error === "string") {
      return error;
    } else if (Array.isArray(error)) {
      return error.join(", ");
    } else if (typeof error === "object") {
      return "An error occurred";
    }
    return undefined;
  };

  return (
    <PageWrapper>
      <Box sx={{ py: 4, px: 4 }}>
        <Box sx={{ cursor: "pointer", fontSize: "14px", lineHeight: "20px", marginBottom: "16px" }}>
          <Link underline="hover" onClick={() => navigate("/io-config")}>
            <ArrowBackIcon sx={{ width: "16px", height: "16px", marginRight: "4px" }} /> Back to IO App Config
          </Link>
        </Box>
        <Formik
          onSubmit={handleSubmitForm}
          initialValues={dataValue}
          validationSchema={ioConfigScheme}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            touched,
            handleBlur
          }) => {
            return <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                <CardComponent
                  title={isEdit ? "Edit IO App Config" : "Add IO App Config"}
                  cardContent={
                    <Box sx={{ "& > *:not(:last-child)": { mb: 8 } }}>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel id="type">Type</InputLabel>
                            <Select
                              fullWidth
                              labelId="type"
                              label="type"
                              placeholder="Type"
                              name="type"
                              value={values.type}
                              error={!!touched.type && !!errors.type}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            >
                              {Object.values(IoConfigService.IO_CONFIG_TYPES).map((value, index) => (
                                <MenuItem key={index} value={value}>
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel id="provider">Provider</InputLabel>
                            <Select
                              fullWidth
                              labelId="provider"
                              label="provider"
                              placeholder="provider"
                              name="provider"
                              value={values.provider}
                              error={!!touched.provider && !!errors.provider}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            >
                              {Object.values(IoConfigService.PROVIDER_TYPES).map((value, index) => (
                                <MenuItem key={index} value={value}>
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Provider Id"
                              name="providerId"
                              placeholder="xxx"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.providerId}
                              error={!!touched.providerId && !!errors?.providerId}
                              helperText={touched?.providerId ? formatError(errors?.providerId) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="App Id"
                              placeholder="xxx"
                              name="appId"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.appId}
                              error={!!touched.appId && !!errors?.appId}
                              helperText={touched?.appId ? formatError(errors?.appId) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type={showKey ? "text" : "password"}
                              label="Api Key"
                              name="apiKey"
                              placeholder="app-xxx-xxx"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.apiKey}
                              error={!!touched.apiKey && !!errors?.apiKey}
                              helperText={touched?.apiKey ? formatError(errors?.apiKey) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle api key visibility"
                                      onClick={handleClickShowKey}
                                      onMouseDown={handleMouseDownKey}
                                    >
                                      {showKey ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type={showSecret ? "text" : "password"}
                              label="Authorisation Token"
                              placeholder="Basic xxx"
                              name="authorisationToken"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.authorisationToken}
                              error={!!touched.authorisationToken && !!errors?.authorisationToken}
                              helperText={touched?.authorisationToken ? formatError(errors?.authorisationToken) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle api key visibility"
                                      onClick={handleClickShowSecret}
                                      onMouseDown={handleMouseDownSecret}
                                    >
                                      {showSecret ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Api End Point"
                              placeholder="api.xxx.xxx"
                              name="apiEndPoint"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.apiEndPoint}
                              error={!!touched.apiEndPoint && !!errors?.apiEndPoint}
                              helperText={touched?.apiEndPoint ? formatError(errors?.apiEndPoint) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Host End Point"
                              name="hostEndPoint"
                              placeholder="host.xxx.xxx"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.hostEndPoint}
                              error={!!touched.hostEndPoint && !!errors?.hostEndPoint}
                              helperText={touched?.hostEndPoint ? formatError(errors?.hostEndPoint) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Scope"
                              name="scope"
                              placeholder="scope"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.scope}
                              error={!!touched.scope && !!errors?.scope}
                              helperText={touched?.scope ? formatError(errors?.scope) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  }
                />
                <Box width="100%" display="flex" mt="10px" mb="10px" mr="10px">
                  <Button
                    style={{ marginTop: 10, width: "100px" }}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    {isEdit ? "UPDATE" : "SAVE"}
                  </Button>
                </Box>
              </Box>

            </form>;
          }}
        </Formik>
      </Box>
    </PageWrapper>
  );
}

export default AddEditIOConfig;
