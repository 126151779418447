/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "eu-west-2",
  "aws_appsync_graphqlEndpoint": "https://wcobajxflrddtfccfogbgbmshm.appsync-api.eu-west-2.amazonaws.com/graphql",
  "aws_appsync_region": "eu-west-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_cognito_identity_pool_id": "eu-west-2:5a3c4343-0098-4559-b6d9-435580bec6c0",
  "aws_cognito_region": "eu-west-2",
  "aws_user_pools_id": "eu-west-2_JEjLnSG3x",
  "aws_user_pools_web_client_id": "4cnkccqrmicjalsq9v7offhhb1",
  "oauth": {
    "domain": "hubwise-adviser-qd.auth.eu-west-2.amazoncognito.com"
  },
  "aws_cognito_username_attributes": [],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [],
  "aws_cognito_mfa_configuration": "OPTIONAL",
  "aws_cognito_mfa_types": [
    "TOTP"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS"
    ]
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ]
};


export default awsmobile;
