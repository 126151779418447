import React, { useState } from "react";
import {
  Button,
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  useCreateInvestorMutation,
  useUpdateInvestorMutation,
  useLazyGetInvestorQuery,
} from "./api/entity-mapping.generated";
import Header from "components/Header";
import SearchIcon from "@mui/icons-material/Search";
import PageWrapper from "components/PageWrapper";

const EntityMappingScreen = () => {
  const theme = useTheme();
  const colors = theme.palette;
  const [searchId, setSearchId] = useState<string>(""); // Input for search
  const [mappings, setMappings] = useState<any[]>([]); // Table data
  const [editRow, setEditRow] = useState<string | null>(null); // Row being edited
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false); // Create dialog state
  const [newRecord, setNewRecord] = useState<any>({
    customerId: "",
    externalId: "",
    internalId: "",
  });
  const { enqueueSnackbar } = useSnackbar();


  // Lazy Query and Mutations
  const [getInvestorQuery] = useLazyGetInvestorQuery(); // Lazy query
  const [createInvestorMutation] = useCreateInvestorMutation();
  const [updateInvestorMutation] = useUpdateInvestorMutation();

  // Handle "Search" Button
  const handleSearch = async () => {
    if (!searchId.trim()) {
      enqueueSnackbar("Please enter a valid HW Investor ID to search.", { variant: "warning" });
      return;
    }
    try {
      const response = await getInvestorQuery({ internalId: searchId });
      if (response.data?.getInvestor?.success) {
        const fetchedData = response.data.getInvestor.data || [];
        setMappings(fetchedData); // Update table with fetched data
        if (fetchedData.length === 0) alert("No results found.");
      } else {
        enqueueSnackbar(response.data?.getInvestor?.errorMessage || "Error fetching data.", { variant: "error" });
        setMappings([]);
      }
    } catch (error) {
      console.error("Error fetching investor mappings:", error);
      enqueueSnackbar("Error fetching investor mappings.", { variant: "error" });
    }
  };

  // Handle Editing a Row
  const handleEdit = (id: string) => {
    setEditRow(id);
  };

  // Handle Cancel Editing
  const handleCancelEdit = () => {
    setEditRow(null);
  };

  // Handle Saving Edited Row
  const handleSave = async (row: any) => {
    try {
      const response = await updateInvestorMutation({
        input: {
          id: row.id,
          externalId: row.externalId,
          internalId: row.internalId,
          customerId: row.customerId
        },
      });
      if (response.data?.updateInvestor?.success) {
        enqueueSnackbar("Record updated successfully.", { variant: "success" });
        setEditRow(null);
      } else {
        enqueueSnackbar(response.data?.updateInvestor?.errorMessage || "Update failed.", { variant: "error" });
      }
    } catch (error) {
      console.error("Error updating record:", error);
      enqueueSnackbar("Failed to update record.", { variant: "error" });
    }
  };

  // Open "Create" Dialog
  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true);
  };

  // Close "Create" Dialog
  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
    setNewRecord({
      customerId: "",
      externalId: "",
      internalId: "",
    });
  };

  // Handle Creating a New Record
  const handleCreateSave = async () => {
    try {
      const response = await createInvestorMutation({
        input: newRecord,
      });
      if (response.data?.createInvestor?.success) {
        handleCloseCreateDialog();
        enqueueSnackbar("New investor mapping created successfully.", { variant: "success" });
      } else {
        const errorMessage = response.data?.createInvestor?.errorMessage;
        if (errorMessage === "User cannot be created because the customerId does not have a valid tenant mapping.") {
          enqueueSnackbar(
            "Creation failed: Mapping for the provided customerId is not available in IO Integrations settings.",
            { variant: "warning" }
          );
        } else {
          enqueueSnackbar(errorMessage || "Creation failed.", { variant: "error" });
        }
      }
    } catch (error) {
      console.error("Error creating new investor mapping:", error);
      enqueueSnackbar("Failed to create new investor mapping.", { variant: "error" });
    }
  };

  return (
    <PageWrapper>
      <Box sx={{ paddingBottom: "64px" }}>
        <Header
          title="Entity Mapping"
          subtitle="Manage Entity Mappings"
        />
        <Typography variant="h2"></Typography>

        <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: 30 }}>
          <Box style={{ display: "flex", gap: 20 }}>
            <TextField
              label="Search by HW Investor ID"
              variant="outlined"
              size="small"
              value={searchId}
              onChange={(e) => setSearchId(e.target.value)}
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSearch}
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
          </Box>
          <Box>
            <Button variant="contained" color="primary" onClick={handleOpenCreateDialog}>
              Create Investor Mapping
            </Button>
          </Box>
        </Box>

        {/* Results Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Customer ID</TableCell>
                <TableCell>External ID</TableCell>
                <TableCell>Internal ID</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Tenant ID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mappings.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.customerId}</TableCell>
                  <TableCell>
                    {editRow === row.id ? (
                      <TextField
                        value={row.externalId}
                        onChange={(e) =>
                          setMappings((prev) =>
                            prev.map((r) =>
                              r.id === row.id ? { ...r, externalId: e.target.value } : r
                            )
                          )
                        }
                        size="small"
                        style={{ width: "120px" }}
                      />
                    ) : (
                      row.externalId
                    )}
                  </TableCell>
                  <TableCell>
                    {editRow === row.id ? (
                      <TextField
                        value={row.internalId}
                        onChange={(e) =>
                          setMappings((prev) =>
                            prev.map((r) =>
                              r.id === row.id ? { ...r, internalId: e.target.value } : r
                            )
                          )
                        }
                        size="small"
                        style={{ width: "120px" }}
                      />
                    ) : (
                      row.internalId
                    )}
                  </TableCell>
                  <TableCell>{row.provider}</TableCell>
                  <TableCell>{row.tenantId}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>
                    {editRow === row.id ? (
                      <>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleSave(row)}
                          >
                            Save
                          </Button>
                          <Button variant="outlined" onClick={handleCancelEdit}>
                            Cancel
                          </Button>
                        </div>
                      </>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleEdit(row.id)}
                      >
                        Edit
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Create Investor Mapping Dialog */}
        <Dialog open={createDialogOpen} onClose={handleCloseCreateDialog}>
          <DialogTitle><Typography variant="h4">Create Investor Mapping</Typography></DialogTitle>
          <DialogContent>
            {Object.keys(newRecord).map((key) => (
              <TextField
                key={key}
                label={key}
                variant="outlined"
                fullWidth
                margin="normal"
                value={newRecord[key]}
                onChange={(e) =>
                  setNewRecord((prev: any) => ({
                    ...prev,
                    [key]: e.target.value,
                  }))
                }
              />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCreateDialog} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleCreateSave} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </PageWrapper>
  );
};

export default EntityMappingScreen;
