import React, { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, Typography, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Cpu, Drawer, IpAddressTag, Suitcase, ViewStructureUp, Settings, ArrowEnlargeTag, CloudUpload, ProfileCircle, UserStar } from "iconoir-react";
import { Link as IconoirLink } from "iconoir-react";

type ItemProps = {
  title: string;
  uniqueTitle: string;
  to: string;
  icon: React.ReactNode;
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
};



const Item: React.FC<ItemProps> = ({
  title,
  uniqueTitle,
  to,
  icon,
  selected,
  setSelected,
}) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <MenuItem
      active={selected === uniqueTitle}
      style={(selected === uniqueTitle) ? {
        color: colors.primary.main,
        backgroundColor: colors.primary.light,
      } : { color: colors.grey[500] }}
      onClick={() => setSelected(uniqueTitle)}
      icon={icon}
      className="menuItem"
    >
      <Typography style={{
        fontFamily: "Lato, sans-serif",
        fontWeight: selected === uniqueTitle ? 700 : 500,
      }}>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const location = useLocation();
  const colors = theme.palette;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("dashboard");
  const props = { selected, setSelected };

  const syncSelectedWithRoute = () => {
    const path = location.pathname;
    const routeMap: { [key: string]: string } = {
      "/": "dashboard",
      "/events": "events",
      "/jobs": "jobs",
      "/state-machines": "machines",
      "/ip-set": "ip-whitelists",
      "/io-config": "io-app-config",
      "/integrations": "intelliflo",
      "/investor-users": "investor-users",
      "/adviser-users": "adviser-users",
      "/entity-mapping": "entity-mapping",
      "/onboarding/csv-to-json": "csv-to-json"
    };

    let matchedRoute = "/";
    for (const route in routeMap) {
      if (path.startsWith(route) && route.length > matchedRoute.length) {
        matchedRoute = route;
      }
    }
    if (matchedRoute) {
      setSelected(routeMap[matchedRoute]);
    }
  };

  useEffect(() => {
    syncSelectedWithRoute();
  }, [location.pathname]);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.cardBackground} !important`,
          borderRight: `1px solid ${colors.cardBorderColor}`,
        },
        "&  .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          backgroundColor: `${colors.primary.light} !important`,
          color: `${colors.primary.main} !important`,
        },
        "& .menu-header:hover": {
          backgroundColor: `${colors.grey[900]} !important`,
        },
        "& .pro-menu": {
          overflowY: "hidden",
          scrollbarGutter: "stable",
          "-ms-overflow-style": "none",
          "&::-webkit-scrollbar": {
            opacity: 0
          },
          transition: "all 0.8s ease-in-out",
        },
        "& .pro-menu:hover": {
          overflowY: "auto",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            opacity: 1
          },
        },
        boxShadow: "4px 8px 16px rgba(64,110,184,0.16), 0px 0px 8px rgba(64,110,184,0.04)",
        zIndex: "2"
      }}
    >
      <ProSidebar>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{
            maxHeight: "20rem",
            maxWidth: "20rem",
            marginTop: "2rem",
            marginBottom: 30
          }}
        >
          <Box
            component="img"
            alt="ss&c-logo"
            src="../../../assets/images/ssc-hubwise-logo.svg"
            sx={{
              width: 225,
              height: "auto"
            }}
          />
          <Typography variant="h5" sx={{ fontWeight: 700, color: colors.blueAccent[400], marginTop: 2, letterSpacing: 2, fontSize: 16 }}>ADMIN PLATFORM</Typography>
        </Box>
        <Menu>
          <Box paddingLeft="5%">
            <Item title="Dashboard" uniqueTitle="dashboard" to="/" icon={<ViewStructureUp />} {...props} />
            <Typography variant={isCollapsed ? "h6" : "h5"} color={colors.grey[500]} sx={{ m: "15px 0px 5px 20px", fontWeight: 600 }}>Data</Typography>
            <Item title="Events" uniqueTitle="events" to="/events" icon={<Drawer />} {...props} />
            <Typography variant={isCollapsed ? "h6" : "h5"} color={colors.grey[500]} sx={{ m: "15px 0px 5px 20px", fontWeight: 600 }}>System</Typography>
            <Item title="Jobs" uniqueTitle="jobs" to="/jobs" icon={<Suitcase />} {...props} />
            <Item title="Machines" uniqueTitle="machines" to="/state-machines" icon={<Cpu />} {...props} />
            <Item title="IP Whitelists" uniqueTitle="ip-whitelists" to="/ip-set" icon={<IpAddressTag />} {...props} />
            <Typography variant={isCollapsed ? "h6" : "h5"} color={colors.grey[500]} sx={{ m: "15px 0px 5px 20px", fontWeight: 600 }}>Integration</Typography>
            <Item title="IO App Config" uniqueTitle="io-app-config" to="/io-config" icon={<Settings />} {...props} />
            <Item title="Intelliflo" uniqueTitle="intelliflo" to="/integrations" icon={<ArrowEnlargeTag />} {...props} />
            <Item title="Entity Mapping" uniqueTitle="entity-mapping" to="/entity-mapping" icon={<IconoirLink />} {...props} />
            <Typography variant={isCollapsed ? "h6" : "h5"} color={colors.grey[500]} sx={{ m: "15px 0px 5px 20px", fontWeight: 600 }}>Onboarding</Typography>
            <Item title="Portal Onboarding" uniqueTitle="csv-to-json" to="/onboarding/csv-to-json" icon={<CloudUpload />} {...props} />
            <Item title="Investor Onboarding" uniqueTitle="investor-users" to="/investor-users" icon={<ProfileCircle />} {...props} />
            <Item title="Adviser Onboarding" uniqueTitle="adviser-users" to="/adviser-users" icon={<UserStar />} {...props} />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
