import {
  Box, Button,
  CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack,
  Tab,
  Table, TableBody, TableCell,
  TableHead, TableRow, Tabs, Typography, useTheme
} from "@mui/material";
import React, { ReactNode, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Header from "../../../components/Header";
import { GetStateMachineSummaryQueryVariables, useGetStateMachineSummaryQuery, useStartExecutionMutation } from "./api/stateMachines.generated";
import { enqueueSnackbar } from "notistack";
import SAMPLE_JSON from "./sample.json";

// @ts-ignore
// eslint-disable-next-line
import AWSSfnGraph from "@tshepomgaga/aws-sfn-graph";
import "@tshepomgaga/aws-sfn-graph/index.css";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PendingIcon from "@mui/icons-material/Pending";
import HourglassDisabledIcon from "@mui/icons-material/HourglassDisabled";

import { ExecutionStatus } from "../../../app/services/types.generated";
import PageWrapper from "../../../components/PageWrapper";
import ReactCodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

const statusMap: Record<ExecutionStatus, { icon: ReactNode, color?: string }> = {
  ABORTED: {
    icon: <CancelOutlinedIcon color="error" />,
    color: "error",
  },
  FAILED: {
    icon: <CancelOutlinedIcon color="error" />,
    color: "error",
  },
  SUCCEEDED: {
    icon: <CheckCircleOutlineIcon color="success" />,
    color: "green",
  },
  RUNNING: {
    icon: <PendingIcon />,
  },
  TIMED_OUT: {
    icon: <HourglassDisabledIcon color="error" />,
    color: "error",
  },
};

const StateMachineSummary = () => {
  const theme = useTheme();
  const colors = theme.palette;

  const navigate = useNavigate();

  const params = useParams();

  const { id = "" } = params;
  const stateMachineParams: GetStateMachineSummaryQueryVariables = { id, };

  const { data, isLoading } = useGetStateMachineSummaryQuery(stateMachineParams);
  const stateMachine = data?.getStateMachineSummary;
  const executions = stateMachine?.executions || [];
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState<any>();
  const [tab, setTab] = useState("input");
  const [startExecution] = useStartExecutionMutation();

  const sampleJson = stateMachine?.name ?
    SAMPLE_JSON.find(f => f.name.includes(stateMachine.name.toLowerCase())) || {} : {} as any;

  const handleClickExecution = () => {
    setOpen(true);
  };

  const handleCancelExecution = () => {
    setOpen(false);
  };

  const handleStartExecution = () => {
    if (id) {
      startExecution({ id, input });
      enqueueSnackbar(`${stateMachine?.name} state machine has been successfully started.`, { variant: "success" });
    } else {
      enqueueSnackbar("State machine doesn't exist.", { variant: "error" });
    }
    setOpen(false);
  };

  const handleCopyExecution = () => {
    navigator.clipboard.writeText(JSON.stringify(sampleJson?.value || {}, null, 4));
    enqueueSnackbar("Copied.", { variant: "success" });
  };

  const handleTabChange = (event: any, value: any) => {
    setTab(value);
  };

  const aslData = isLoading || !stateMachine ? null : JSON.parse(stateMachine.definition.replaceAll("ItemProcessor", "Iterator"));


  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <PageWrapper>
      <Box display="flex" justifyContent="space-between">
        <Header title={stateMachine ? `${stateMachine.name}` : ""} subtitle={stateMachine ? `${stateMachine.id}` : ""} />
        <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleClickExecution}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Start Execution
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box>
        <Typography variant="h3">Machine Graph</Typography>
        <Box sx={{
          height: "500px",
          width: "100%",
          border: colors.cardBorderColor,
        }}>
          <AWSSfnGraph data={aslData} height={500} onError={console.log} />
        </Box>
      </Box>

      <Typography variant="h5">Recent Executions</Typography>
      <Table size="small" id="recent-execution-table">
        <TableHead>
          <TableCell>Start Time</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>End Time</TableCell>
          <TableCell></TableCell>
        </TableHead>
        <TableBody>
          {executions.map(execution => (
            <TableRow>
              <TableCell>{execution.startDate}</TableCell>
              <TableCell>
                <Stack direction="row" alignItems="center" gap={0.25}>
                  {statusMap[execution.status].icon}
                  <Typography color={statusMap[execution.status].color}>
                    {execution.status}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>{execution.stopDate}</TableCell>
              <TableCell>
                <Button
                  size="small"
                  onClick={() => navigate(`/state-machines/${id}/execution/${execution.id}`)}
                >
                  View
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog
        open={open}
        onClose={handleCancelExecution}
      >
        <DialogTitle fontSize={24}>Enter the Json input to start the execution.</DialogTitle>
        <DialogContent>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="Input" value="input" />
            <Tab label="Sample" value="sample" />
          </Tabs>
          <DialogContentText mt={1}>
            {tab === "input" && <ReactCodeMirror
              minHeight="100px"
              editable={true}
              placeholder="(Optional)"
              theme={theme.palette.mode}
              extensions={[javascript()]}
              value={input}
              onChange={(event) => setInput(event)}
            />}
            {tab === "sample" && <ReactCodeMirror
              minHeight="100px"
              editable={false}
              theme={theme.palette.mode}
              extensions={[javascript()]}
              value={JSON.stringify(sampleJson?.value || {}, null, 4)}
            />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelExecution} color="primary">
            Cancel
          </Button>
          {tab === "input" && <Button onClick={handleStartExecution} color="primary" autoFocus>
            Start
          </Button>
          }
          {tab === "sample" && <Button onClick={handleCopyExecution} color="primary" autoFocus>
            Copy
          </Button>

          }
        </DialogActions>
      </Dialog>
    </PageWrapper>
  );
};
export default StateMachineSummary;
