import { fetchAuthSession } from "aws-amplify/auth";

const awsgateway = {
  API: {
    endpoints: [
      {
        name: "PortalApi",
        endpoint:
          "https://io-wealtlink-qd.gateway.hubwise.co.uk/api/hubwise-api",
        custom_header: async () => {
          return {
            Authorization: (await fetchAuthSession()).tokens?.accessToken
          };
        },
      },
      {
        name: "IntellifloApi",
        endpoint: "https://io-integration-qd.gateway.hubwise.co.uk",
      },
      {
        name: "UserManagementApi",
        endpoint:
          "https://user-management-qd.gateway.hubwise.co.uk/v1/user-management",
        custom_header: async () => {
          return {
            Authorization: (await fetchAuthSession()).tokens?.idToken
          };
        },
      },
      {
        name: "ChangelogAPi",
        endpoint : "https://api-changelog.hubwise.co.uk/changelog?stage=qd",
        custom_header: async () => {
          return {
            Authorization: (await fetchAuthSession()).tokens?.idToken,
            Environment: "staging",
          };
        },

      }
    ],
  },
};

export default awsgateway;
