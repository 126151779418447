import React, { forwardRef, useState } from "react";
import {
  TableCell,
  TableRow,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router";
import MenuComponent, { MenuItem } from "components/MenuComponent";
import { Investor } from "../../model";
import investorStore from "../../api";
import { enqueueSnackbar } from "notistack";

interface TableRowProps {
  row: Investor;
}

const InvestorUsersRow = forwardRef<HTMLTableRowElement, TableRowProps>((props) => {
  const navigate = useNavigate();
  const row = props.row;

  const { disableMFAInvestor } = investorStore;
  const handleDisableMFA = async () => {
    try {
      await disableMFAInvestor({ username: row.username, status: false });
      enqueueSnackbar(`MFA disabled successfully for ${row.username}`, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(`Error disabling MFA for ${row.username}`, { variant: "error" });

    }
  };
  const options: MenuItem[] = [
    {
      label: "Edit",
      onClick: () => navigate("/investor-users/add-edit/" + row.id),
    },
    {
      label: "Disable MFA",
      onClick: handleDisableMFA,
    },
    {
      label: "User Activity",
      onClick: () => navigate(`/investor-users/user-activity/${row.id}`, { state: { username: row.username } })
    }
  ];

  return (
    <>
      <TableRow sx={{ borderBottom: "none", "& > *": { borderBottom: "unset" } }}>
        <TableCell>{row?.first_name} {row?.last_name}</TableCell>
        <TableCell>{row?.username}</TableCell>
        <TableCell>{row?.email}</TableCell>
        <TableCell>{row?.phone_number}</TableCell>
        <TableCell>{row?.created_at}</TableCell>
        <TableCell>
          <MenuComponent options={options} />
        </TableCell>
      </TableRow>
    </>
  );
});

export default InvestorUsersRow;
